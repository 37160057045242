import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/seo'
import Post from '../components/Post/Post'
import SocialNetworkShare from '../components/UI/SocialNetworkShare/SocialNetworkShare'

const SingleArticle = ({ data, pageContext }) => {
  const { frontmatter, html, excerpt } = data.blog
  const { siteUrl } = data.site.siteMetadata
  const { name, image } = data.author.frontmatter
  const shareConfig = {
    articleUrl: `blog/${ pageContext.slug }`,
    title: frontmatter.title
  }
  return (
    <>
      <SEO
        title={frontmatter.title}
        keywords={['article', 'Bluepes']}
        description = {excerpt}
        meta={[
          {
            property: `og:image`,
            content: `${ siteUrl }/${ frontmatter.sharedImage.childImageSharp.fixed.src }`,
          },
          {
            name: `twitter:image`,
            content: `${ siteUrl }/${ frontmatter.sharedImage.childImageSharp.fixed.src }`,
          }
        ]}
      />
      <div className="single-article-page">
        <article className="article">
          <h1 className="article-header">
            {frontmatter.title}
          </h1>
          <div className="author-wrapper">
            <div className="author-image">
              <Img
                fixed={image.childImageSharp.fixed}
                alt={name}
              />
            </div>
            <div className="authorname-date-block">
              <div className="authorname">
                {name}
              </div>
              <div className="article-date">
                {frontmatter.date}
              </div>
            </div>
          </div>
          <div className="article-image">
            <Img
              fluid={frontmatter.image.childImageSharp.fluid}
              alt={frontmatter.title}
            />
          </div>
          <div
            className="article-text list-style article-link-style"
            dangerouslySetInnerHTML={{
              __html: html
            }}
          />
          <div className="social-network">
            <ul className="social-network-list">
              <li>
                <SocialNetworkShare
                  socialNetworkName = "facebook"
                  shareConfig = {shareConfig}
                />
              </li>
              <li>
                <SocialNetworkShare
                  socialNetworkName = "twitter"
                  shareConfig = {shareConfig}
                />
              </li>
              <li>
                <SocialNetworkShare
                  socialNetworkName = "linkedIn"
                  shareConfig = {shareConfig}
                />
              </li>
            </ul>
          </div>
        </article>
        <section className="other-articles">
          <div className="other-articles-wrapper wrapper">
            <h1 className="section-header">OTHER ARTICLES</h1>
            <div className="blog-articles-wrapper">
              {data.allMarkdownRemark.edges.map(({ node }) => {
                return (
                  <Post
                    key={node.id}
                    title={node.frontmatter.title}
                    slug={`/blog/${ node.fields.slug }`}
                    image={node.frontmatter.image.childImageSharp.fluid}
                    date={node.frontmatter.date}
                  />
                )
              })}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export const postQuery = graphql`
  query blogPostBySlug($slug: String!, $author: String!) {
    blog:markdownRemark(fields: { slug: { eq: $slug }}) {
      id
      html
      excerpt(pruneLength: 250)
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        image {
          childImageSharp {
            fluid(
              maxWidth: 1224, 
              maxHeight: 702, 
              quality: 80,
              cropFocus: CENTER,
              srcSetBreakpoints: [736, 768, 928, 1224]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        sharedImage {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
    author:markdownRemark(frontmatter: { name: { eq: $author }} ) {
      frontmatter {
        name
        image {
          childImageSharp {
            fixed(
              width: 46, 
              height: 46, 
              quality: 80,
              cropFocus: CENTER,
            ) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark (
      limit: 6
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {fields: {slug: {ne: $slug}}, fileAbsolutePath: {regex: "/content/blog/"}}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fluid(
                  maxWidth: 456, 
                  maxHeight: 456, 
                  quality: 80, 
                  cropFocus: CENTER,
                  srcSetBreakpoints: [224, 288, 344, 352, 456]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default SingleArticle
