import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Facebook from '../../../assets/icons/facebook.svg'
import Linkedin from '../../../assets/icons/linkedin.svg'
import Twitter from '../../../assets/icons/twitter.svg'

const socialShareHandler = (e, url) => {
  const width = 700
  const height = 500
  const left = (screen.width / 2) - (width / 2)
  const top = (screen.height / 2) - (height / 2)

  e.preventDefault()
  window.open(
    url,
    '',
    `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${ width },
    height=${ height },top=${ top },left=${ left }`
  )
}

const SocialNetworkShare = props => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `)
  const baseUrl = `${ data.site.siteMetadata.siteUrl }/${ props.shareConfig.articleUrl }`

  switch (props.socialNetworkName) {
  case 'facebook':
    let urlFacebook = `https://www.facebook.com/sharer.php?u=${ baseUrl }`
    return <Facebook onClick={e => socialShareHandler(e, urlFacebook)} />

  case 'twitter':
    let urlTwitter = `https://twitter.com/intent/tweet?text=${ props.shareConfig.title }
    &url=${ baseUrl }`
    return <Twitter onClick={e => socialShareHandler(e, urlTwitter)} />

  case 'linkedIn':
    let urlLinkedIn = `https://www.linkedin.com/shareArticle?mini=true&url=${ baseUrl }`
    return <Linkedin onClick={e => socialShareHandler(e, urlLinkedIn)} />
  }
}

export default SocialNetworkShare
